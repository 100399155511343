import React, { Component } from 'react';

import { fetchArticles } from '../../reducers/articles';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchDashboardKPIs } from '../../actions/actions_kpis';
import CostsSavingsKPI from './kpis/CostsSavingsKPI';
import BuyPerformanceKPI from './kpis/BuyPerformanceKPI';
import MDsComments from './kpis/MDsComments';
import ProductAge from './kpis/ProductAge';
import RiskManagement from './kpis/RiskManagement';
import withRouter from '@/components/utilities/withRouter';

class KPIDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forecast_accuracy: [],
      product_age: [],
      risk_management: [],
      quality: [],
      configsObject: {},
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchEntitiesData(['grinder', 'config']));
    this.props.dispatch(fetchArticles(this.props.token));
    this.props.dispatch(
      fetchDashboardKPIs(
        false,
        false,
        false,
        false,
        'cost_savings,cost_savings_target,additional_value,fmg_buy_performance,usda_buy_performance',
        this.props.token
      )
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.configs && nextProps.configs.length) {
      const configsObject = {};
      nextProps.configs.forEach(function (config) {
        configsObject[config.name] = config;
      });

      this.setState({ configsObject });
    }
  }

  render() {
    const { configsObject } = this.state;
    const { articles, kpis, grinders } = this.props;
    const { cost_savings_target, cost_savings, additional_value, fmg_buy_performance, usda_buy_performance } = kpis;

    return (
      <div className="m-portlet__body">
        <MDsComments articles={articles} />
        <hr />
        <CostsSavingsKPI
          cost_savings_target={cost_savings_target}
          cost_savings={cost_savings}
          additional_value={additional_value}
          grinders={grinders}
        />
        <hr />
        <BuyPerformanceKPI fmg_buy_performance={fmg_buy_performance} usda_buy_performance={usda_buy_performance} />
        <ProductAge configsObject={configsObject} />
        <hr />
        <RiskManagement configsObject={configsObject} />
      </div>
    );
  }
}

export default withRouter(KPIDashboard);
