import { selectKPIRecentData, fetchAllKPIData } from '../../slices/kpi/kpiSlice';
import { selectArticleRegions, fetchArticleRegions } from '../../slices/article-region/articleRegionSlice';
import ArticleCardList from '../core/articles/ArticleCardList';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, VStack } from '@chakra-ui/react';
// Local Deps
import Loading from '../basic/Loading';
import { Link, useParams } from 'react-router';
import ArticleRegionCardList from '../core/articles/ArticleRegionCardList';
import AuthGate from '../../containers/auth/AuthGate';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import Constants from '../../Constants';
import IUnleashClassFlagProvider from '../unleash/UnleashClassFlagProvider';

const KPI = () => {
  const { region } = useParams();
  const dispatch = useDispatch();
  const articles = useSelector(selectKPIRecentData);
  const articleRegions = useSelector(selectArticleRegions);

  useEffect(() => {
    dispatch(fetchArticleRegions());
    if (region) {
      dispatch(fetchAllKPIData(region));
    }
  }, [region]);

  const buttonStyleProps = {
    colorScheme: 'actionSecondary',
    fontSize: '16px',
    height: '43px',
    fontWeight: '600',
  };
  return (
    <VStack background="white" align="stretch" gap="28px">
      {!region ? (
        <HorizontalNavigationBand>
          <Link to="/kpi-archive">
            <Button {...buttonStyleProps}>Insights Archive</Button>
          </Link>
        </HorizontalNavigationBand>
      ) : (
        <AuthGate requiredPermissions={['articles_write']}>
          <HorizontalNavigationBand>
            <Link to={`/kpi/${region}/edit/new`}>
              <Button {...buttonStyleProps}>Create New KPI</Button>
            </Link>
          </HorizontalNavigationBand>
        </AuthGate>
      )}
      {!region && (
        <>{articleRegions.length ? <ArticleRegionCardList articleRegions={articleRegions} /> : <Loading />}</>
      )}
      {!!region && <>{articles ? <ArticleCardList articles={articles} groupByYear /> : <Loading />}</>}
    </VStack>
  );
};

const KPIUnleashController = props => {
  return (
    <IUnleashClassFlagProvider
      flagName={Constants.UNLEASH_FLAG.KPI_INSIGHTS}
      show={<KPI {...props} />}
      hide={<h1>Feature Not Enabled</h1>}
    />
  );
};

export default KPIUnleashController;
