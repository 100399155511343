/* eslint-disable default-case */
import { Tabs, Box, VStack, Center } from '@chakra-ui/react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import {
  clearInvoiceError,
  fetchOrderInvoicesToExport,
  fetchOrdersToInvoice,
  generateInvoiceSummary,
  previewInvoices,
  resetInvoicingState,
  sendInvoicingEmail,
} from '../../actions/actions_invoicing';
import { priceCartOrders, priceOrders } from '../../actions/actions_priced_orders';
import Constants from '../../Constants';
import { downloadFromS3Link } from '../../helpers/downloads';
// Components
import Loading from '../basic/Loading/Loading';
// Local Deps
import './FinancePage.scss';
import {
  addSelectionsToCart,
  calculateInvoiceDueDate,
  determinePriceStatus,
  determineSubmitButtonText,
  getSelectionsKeyByTab,
  preparePurchaseOrderForInvoice,
  preparePurchaseOrderForPricing,
  validateOrdersForPriceLocking,
  validateRows,
  isUSRegionGrinder,
  validateOrdersForPriceInvoice,
  validateMandatoryFields,
  formatRowsForCart,
} from './helpers';
import InvoiceCart from './InvoiceCart';
import InvoiceEmailModal from './InvoiceEmailModal';
import InvoiceResults from './InvoiceResults';
import {
  getPricingStrategy,
  isManualPriceStrategyByConfigsAndEndUser,
  getDefaultValueFromConfigs,
  getInternalPoLabel,
  isFinanceTableRowsValidationIgnored,
} from '../../utils';
import { fetchConfigs } from '../../actions/actions_config';
import { fetchEndUsers } from '../../actions/actions_end_user';
import { fetchPackageWeights } from '../../actions/actions_package_weights';
import RecipientsCard from '../coldstore/release-management/RecipientCard/RecipientsCard';
import { getNotifyingActionGroups } from '../../actions/actions_notifying_action_groups';
import DashboardSectionTile from '../core/DashboardSectionTile';
import InvoiceFilters from './InvoiceFilters';
import InvoiceTotals from './InvoiceTotals';
import { FinanceNavMainTabs, ResultTab } from './FinanceNavMainTabs';
import { getEmailTemplate } from '../../services/email-templates';
import { configureColumns, getCartConfig } from './invoiceGridConfig';
import { uniq } from 'lodash';
import { generateAndDownloadExcel } from '../../services/excel-export';
import { Alert } from '../ui/alert';

const { FINANCE_CARTS: CARTS, FINANCE_TABS: TABS, FINANCE_TABS_LIST } = Constants;

const DEFAULT_ACTIVE_TAB = TABS.results;
const DEFAULT_ACTIVE_CART = CARTS.priceCart;

class FinancePage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    token: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
      email: PropTypes.string,
      token: PropTypes.string,
    }),
    configs: PropTypes.arrayOf(PropTypes.object),
    grinders: PropTypes.arrayOf(PropTypes.object).isRequired,
    invoicing: PropTypes.shape({
      orderList: PropTypes.arrayOf(PropTypes.object).isRequired,
      invoiceSummary: PropTypes.string,
      orderPagination: PropTypes.instanceOf(Object).isRequired,
      invoicePDFList: PropTypes.arrayOf(PropTypes.object).isRequired,
      sentInvoiceList: PropTypes.arrayOf(PropTypes.object).isRequired,
      pricedOrderList: PropTypes.arrayOf(PropTypes.object).isRequired,
      pricedCartOrderList: PropTypes.arrayOf(PropTypes.object).isRequired,
      isLoadingPricedOrders: PropTypes.bool,
      isLoadingOrders: PropTypes.bool,
      isLockingPrices: PropTypes.bool,
      error: PropTypes.string,
      isSendingInvoices: PropTypes.bool,
    }),
    endUsers: PropTypes.arrayOf(PropTypes.object),
    packageWeights: PropTypes.arrayOf(PropTypes.object),
    notifyingActionGroups: PropTypes.arrayOf(PropTypes.object),
  };

  state = {
    hasSubmittedForm: false,
    resultOrderSelections: {},
    pricedOrderList: [],
    cartList: [],
    preSelectedCartList: [],
    cartSelections: {},
    selectedTab: DEFAULT_ACTIVE_TAB,
    selectedCart: DEFAULT_ACTIVE_CART,
    grinderUid: null,
    dateType: '',
    dateStart: '',
    dateEnd: '',
    invoiceType: '',
    priceStatus: '',
    page: 1,
    emailModalIsVisible: false,
    pendingPDFDownloadList: [],
    isDownloadingAllAttachments: false,
    isLockingPrices: false,
    isGeneratingInvoiceSummary: false,
    isBatch: true,
    priceLockingErrorList: [],
    endUserId: null,
    selectedEmailList: [],
    initialSubject: '',
    initialBody: '',
    mandatoryFieldsErrors: [],
    isExportingExcel: false,
  };

  async componentDidMount() {
    const { token, dispatch, grinders, configs, endUsers, notifyingActionGroups } = this.props;
    const { initialSubject, initialBody, grinderUid } = this.state;
    dispatch(fetchEntitiesData(['grinder']));
    dispatch(fetchPackageWeights());
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
    if (endUsers.length === 0) {
      dispatch(fetchEndUsers(token));
    }
    if (notifyingActionGroups.length === 0) {
      dispatch(getNotifyingActionGroups(token));
    }

    if (!initialSubject || !initialBody) {
      const body = await getEmailTemplate('invoice_email_body');
      const subject = await getEmailTemplate('invoice_email_subject');
      this.setState({ initialSubject: subject, initialBody: body });
    }
    if (grinderUid) {
      const grinder = grinders.find(g => g.uid === grinderUid);
      this.setState({ isBatch: grinder.invoice_email_strategy === 'Batch' ? true : false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { dispatch, token, invoicing } = this.props;
    const {
      grinderUid,
      dateType,
      dateStart,
      dateEnd,
      invoiceType,
      pendingPDFDownloadList,
      isDownloadingAllAttachments,
      isLockingPrices,
      emailModalIsVisible,
      cartList,
      priceStatus,
      page,
      endUserId,
      invoiceOffice,
      initialBody,
      initialSubject,
    } = this.state;
    // Handle new search:
    if (
      prevState.grinderUid !== grinderUid ||
      prevState.dateType !== dateType ||
      prevState.dateStart !== dateStart ||
      prevState.dateEnd !== dateEnd ||
      prevState.page !== page ||
      prevState.invoiceType !== invoiceType ||
      prevState.priceStatus !== priceStatus ||
      prevState.endUserId !== endUserId ||
      prevState.invoiceOffice !== invoiceOffice
    ) {
      const filters = {
        grinder: grinderUid,
        dateType,
        page,
        invoiceType,
        ...(priceStatus && { priceStatus }),
        ...(dateStart && { dateTypeStart: dateStart }),
        ...(dateEnd && { dateTypeEnd: dateEnd }),
        endUserId,
        invoiceOffice,
      };

      dispatch(fetchOrdersToInvoice(token, filters));
      if (grinderUid) {
        const grinder = this.props.grinders.find(g => g.uid === grinderUid);
        this.setState({ isBatch: grinder.invoice_email_strategy === 'Batch' ? true : false });
      }
    }

    // Handle Download All Attachments button click:
    if (
      pendingPDFDownloadList.length &&
      invoicing.invoicePDFList.length &&
      invoicing.invoicePDFList !== prevProps.invoicing.invoicePDFList
    ) {
      invoicing.invoicePDFList.forEach(
        async attachment => await new Promise(() => downloadFromS3Link(attachment.link))
      );
      const completedPDFDownloadList = invoicing.invoicePDFList.map(pdf => pdf.purchase_order_id);
      const updatedPendingList = pendingPDFDownloadList.filter(po => !completedPDFDownloadList.includes(po.id));

      this.setState({
        pendingPDFDownloadList: updatedPendingList,
        isDownloadingAllAttachments: false,
      });
    }

    // Handle error thrown in email modal:
    if (invoicing.error && isDownloadingAllAttachments) {
      this.setState({
        pendingPDFDownloadList: [],
        isDownloadingAllAttachments: false,
      });
    }

    // Handle actions after email is successfully sent:
    // 1. Email modal is closed
    // 2. Invoice cart is updated
    if (invoicing.sentInvoiceList.length && emailModalIsVisible) {
      const sentOrdersIdList = invoicing.sentInvoiceList.map(invoice => parseInt(invoice.purchase_order_id, 10));
      const updatedCartList = cartList.filter(order => !sentOrdersIdList.includes(order.id));
      this.setState(
        {
          cartList: updatedCartList,
          cartSelections: {},
          emailModalIsVisible: false,
        },
        () => {
          const filters = {
            grinder: grinderUid,
            dateType,
            page,
            invoiceType,
            ...(priceStatus && { priceStatus }),
            ...(dateStart && { dateStart }),
            ...(dateEnd && { dateEnd }),
            endUserId,
          };

          dispatch(resetInvoicingState());
          dispatch(fetchOrdersToInvoice(token, filters));
          window.scrollTo(0, 0);
        }
      );
    }

    // Re-price orders in search results tab when list is updated
    if (prevProps.invoicing.orderList !== invoicing.orderList) {
      const payloadList = invoicing.orderList.map(po => preparePurchaseOrderForPricing(po, dateType));
      const pricingConfig = {
        orders: payloadList,
        shouldLockPrice: false,
        shouldLockRates: false,
        fdrRatesToLock: [],
      };
      dispatch(priceOrders(pricingConfig));
    }

    // Update orders in Search Results tab with pricing data that is received
    if (prevProps.invoicing.pricedOrderList !== this.props.invoicing.pricedOrderList) {
      const { orderList, pricedOrderList } = invoicing;
      // Need to inject pricing data into result order list, since list potentially
      // has user-specified properties
      const updatedResultOrderList = orderList.map(order => {
        const match = pricedOrderList.find(pricedOrder => get(pricedOrder, 'purchase_order.id') === order.id);
        return {
          ...order,
          pricing_details: get(match, 'pricing_details'),
        };
      });
      this.setState({
        pricedOrderList: updatedResultOrderList,
      });
    }

    // Update prders in cart with pricing data that is received
    if (prevProps.invoicing.pricedCartOrderList !== invoicing.pricedCartOrderList) {
      const { pricedCartOrderList } = invoicing;
      if (isLockingPrices) {
        const pricedOrdersIdList = pricedCartOrderList.reduce((agg, result) => {
          if (get(result, 'price_bucket.lock_date')) {
            const id = get(result, 'purchase_order.id');
            return [...agg, parseInt(id, 10)];
          }
          return agg;
        }, []);
        const updatedList = cartList.filter(order => !pricedOrdersIdList.includes(order.id));
        this.setState({
          isLockingPrices: false,
          cartList: updatedList,
        });
      } else {
        // Need to inject pricing data into result order list, since list potentially
        // has user-specified properties
        const updatedCartList = cartList.map(order => {
          const match = pricedCartOrderList.find(pricedOrder => get(pricedOrder, 'purchase_order.id') === order.id);
          return {
            ...order,
            pricing_details: get(match, 'pricing_details'),
          };
        });
        this.setState({
          cartList: updatedCartList,
        });
      }
    }

    // Download Invoice Summary
    if (prevProps.invoicing.invoiceSummary !== invoicing.invoiceSummary && invoicing.invoiceSummary) {
      this.setState(
        {
          isGeneratingInvoiceSummary: false,
        },
        () => {
          downloadFromS3Link(invoicing.invoiceSummary);
          // resetInvoic
        }
      );
    }

    if (!initialSubject || !initialBody || prevState.invoiceType !== invoiceType) {
      const emailTemplateSubjectId = invoiceType
        ? `${invoiceType.toLowerCase()}_invoice_email_subject`
        : 'invoice_email_subject';

      const body = await getEmailTemplate('invoice_email_body');
      const subject = await getEmailTemplate(emailTemplateSubjectId);
      this.setState({ initialSubject: subject, initialBody: body });
    }
  }

  handleRowSelect = selectionsSet => {
    const { selectedTab, pricedOrderList, preSelectedCartList, cartList, dateType } = this.state;

    const selectionsKey = getSelectionsKeyByTab(selectedTab);

    const selectionsMap = [...selectionsSet].reduce((agg, key) => {
      return {
        ...agg,
        [key]: key,
      };
    }, {});
    const preSelectedCarts = addSelectionsToCart({
      orders: pricedOrderList,
      selections: selectionsMap,
      cartContents: uniq(
        [...cartList, ...preSelectedCartList.filter(preSelectedCart => selectionsMap[preSelectedCart.id])],
        'id'
      ),
      dateType,
    });
    this.setState({
      [selectionsKey]: selectionsMap,
      preSelectedCartList: preSelectedCarts,
    });
  };

  handleCartReset = () => {
    this.setState({
      cartList: [],
      cartSelections: {},
      hasError: false,
      priceLockingErrorList: [],
    });
  };

  handleTabChange = e => {
    this.setState({
      selectedTab: e.value,
    });
  };

  handleBatchChange = batch => {
    this.setState({
      isBatch: batch,
    });
  };

  handleSearch = values => {
    const { grinders } = this.props;
    const {
      grinder: grinderUid,
      dateType,
      dateStart,
      dateEnd,
      invoiceType,
      searchFlow,
      endUserId,
      invoiceOffice,
    } = values;

    const grinderObject = grinders.find(g => g.uid === grinderUid);

    const isUSAGrinder = isUSRegionGrinder(grinderObject);

    const priceStatus = determinePriceStatus({
      selectedCart: searchFlow,
      grinder: grinderObject,
    });

    this.setState({
      selectedTab: TABS.results,
      hasSubmittedForm: true,
      resultOrderSelections: {},
      cartSelections: {},
      cartList: [],
      grinderUid,
      dateType,
      dateStart,
      dateEnd,
      invoiceType,
      selectedCart: isUSAGrinder ? searchFlow : CARTS.invoiceCart,
      priceStatus,
      endUserId,
      invoiceOffice,
    });
  };

  handleCancelSearch = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        hasSubmittedForm: false,
        dateType: '',
        dateStart: '',
        dateEnd: '',
        preSelectedCartList: [],
      },
      () => dispatch(resetInvoicingState())
    );
  };

  handleSelectionsSubmit = () => {
    const {
      selectedTab,
      selectedCart,
      resultOrderSelections,
      cartSelections,
      pricedOrderList,
      cartList,
      preSelectedCartList,
      dateType,
    } = this.state;
    const { dispatch, configs } = this.props;
    const ignoreValidation = isFinanceTableRowsValidationIgnored(configs);

    this.setState({
      priceLockingErrorList: [],
    });
    switch (selectedTab) {
      case TABS.results: {
        const updatedCartList = addSelectionsToCart({
          orders: pricedOrderList,
          selections: resultOrderSelections,
          cartContents: preSelectedCartList,
          dateType,
        });
        this.setState({
          selectedTab: TABS.cart,
          cartList: updatedCartList,
          preSelectedCartList: [],
          resultOrderSelections: {},
        });
        window.scrollTo(0, 0);
        break;
      }
      case TABS.cart: {
        switch (selectedCart) {
          case CARTS.invoiceCart: {
            const selectedOrders = cartList.reduce(
              (agg, order) => (cartSelections[order.id] ? [...agg, order] : agg),
              []
            );
            const selectedGrinder = this.props.grinders.find(grinder => grinder.uid === this.state.grinderUid);
            const columnKeyList = getCartConfig(this.props, selectedCart, selectedGrinder, this.state.invoiceType);
            let requiredFieldErrors = [];
            if (!ignoreValidation) {
              requiredFieldErrors = validateMandatoryFields(selectedOrders, columnKeyList);
            }

            const errorList = validateOrdersForPriceInvoice(selectedOrders);
            if (isEmpty(errorList) && isEmpty(requiredFieldErrors)) {
              this.setState({
                emailModalIsVisible: true,
              });
            } else {
              this.setState({
                priceLockingErrorList: errorList,
                mandatoryFieldsErrors: requiredFieldErrors,
              });
            }
            window.scrollTo(0, 0);
            break;
          }

          case CARTS.priceCart: {
            const selectedOrders = cartList.reduce(
              (agg, order) => (cartSelections[order.id] ? [...agg, order] : agg),
              []
            );
            const errorList = validateOrdersForPriceLocking(selectedOrders, configs);
            if (isEmpty(errorList)) {
              this.setState(
                {
                  isLockingPrices: true,
                  pricingLockingErrorList: [],
                },
                () => {
                  const payloadList = selectedOrders.map(po => preparePurchaseOrderForPricing(po, dateType));

                  dispatch(
                    priceCartOrders({
                      orders: payloadList,
                      shouldLockPrice: true,
                      shouldUpdateSellPpu: true,
                      shouldUpdateCosPpu: true,
                    })
                  );
                }
              );
            } else {
              this.setState({
                priceLockingErrorList: errorList,
              });
            }
          }
        }

        window.scrollTo(0, 0);
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = ({ body, subject, poList, batch }) => {
    // Dispatch action with email data
    const { token, dispatch } = this.props;
    const { dateType, selectedEmailList } = this.state;
    const augmentedPOList = poList.map(po => preparePurchaseOrderForInvoice(po, dateType));
    // const recipientsList = recipients.split(',').map(emailAddress => emailAddress.trim());
    const emailPayload = {
      poList: augmentedPOList,
      recipients: selectedEmailList,
      isBatch: batch,
      subject,
      body,
    };

    dispatch(sendInvoicingEmail(token, emailPayload));
  };

  handleModalCancel = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        emailModalIsVisible: false,
        pendingPDFDownloadList: [],
      },
      () => {
        window.scrollTo(0, 0);
        dispatch(clearInvoiceError());
      }
    );
  };

  handleDownloadAllAttachments = attachmentList => {
    const { dispatch, token } = this.props;
    const { isDownloadingAllAttachments, dateType } = this.state;

    // Prevent users from double-clicking
    if (isDownloadingAllAttachments) {
      return;
    }

    this.setState(
      {
        pendingPDFDownloadList: attachmentList,
        isDownloadingAllAttachments: true,
      },
      () => {
        const invoiceList = attachmentList.map(item => preparePurchaseOrderForInvoice(item, dateType));
        dispatch(previewInvoices(token, invoiceList));
      }
    );
  };

  handleDownloadAttachment = attachment => {
    const { dispatch, token } = this.props;
    const { pendingPDFDownloadList, dateType } = this.state;
    if (pendingPDFDownloadList.map(po => po.id).includes(attachment.id)) {
      return;
    }

    this.setState(
      {
        pendingPDFDownloadList: [...pendingPDFDownloadList, attachment],
      },
      () => {
        const payload = preparePurchaseOrderForInvoice(attachment, dateType);
        dispatch(previewInvoices(token, [payload]));
      }
    );
  };

  handleGenerateInvoiceSummary = orderList => {
    const { dispatch, token, grinders } = this.props;
    const { grinderUid, dateType } = this.state;

    const grinder = grinders.find(gdr => gdr.uid === grinderUid);

    this.setState(
      {
        isGeneratingInvoiceSummary: true,
      },
      () => {
        const invoiceList = orderList.map(order => preparePurchaseOrderForInvoice(order, dateType));
        dispatch(generateInvoiceSummary(token, invoiceList, grinder.uid));
      }
    );
  };

  handlePageChange = ({ selected }) => {
    const page = selected + 1;
    this.setState({
      page,
    });
  };

  handleCartRowUpdate = updatedRows => {
    const { dateType } = this.state;
    const { dispatch } = this.props;

    const formattedRows = updatedRows.map(row => ({
      ...row,
      invoice_payment_due_date: row.invoice_payment_due_date || calculateInvoiceDueDate(dateType, row),
    }));

    this.setState(
      {
        cartList: formattedRows,
      },
      () => {
        const payloadList = formattedRows.map(po => preparePurchaseOrderForPricing(po, dateType));

        const priceConfig = {
          orders: payloadList,
          shouldLockPrice: false,
          shouldLockRates: false,
          fdrRatesToLock: [],
        };

        dispatch(priceCartOrders(priceConfig));
      }
    );
  };

  handleCartToggle = selectedCart => {
    this.setState({
      selectedCart,
      selectedTab: selectedCart,
    });
  };

  getSelectedEmailList = selectedEmailList => {
    this.setState({ selectedEmailList });
  };

  render() {
    const { invoicing, grinders, user, endUsers, configs, packageWeights, notifyingActionGroups } = this.props;
    const {
      dateType,
      selectedTab,
      selectedCart,
      hasSubmittedForm,
      resultOrderSelections,
      cartSelections,
      emailModalIsVisible,
      pendingPDFDownloadList,
      isDownloadingAllAttachments,
      isBatch,
      pricedOrderList,
      grinderUid,
      cartList,
      isGeneratingInvoiceSummary,
      priceLockingErrorList,
      isLockingPrices,
      invoiceType,
      endUserId,
      mandatoryFieldsErrors,
      initialBody,
      initialSubject,
      preSelectedCartList,
      isExportingExcel,
    } = this.state;

    const ignoreValidation = isFinanceTableRowsValidationIgnored(configs);
    const grinderOptions = grinders.map(grinder => ({
      ...grinder,
      value: grinder.uid,
      label: grinder.name,
    }));
    const endUserOptions = endUsers
      .filter(endUser => endUser.is_active === true)
      .map(endUser => ({
        value: endUser.id,
        label: endUser.legal_name,
        grinders_uids: endUser.grinders_uids,
      }));
    const selectedGrinder = grinders.find(grinder => grinder.uid === grinderUid);

    const sortedGrinderOptions = sortBy(grinderOptions, 'label');
    const sortedEndUserOptions = sortBy(endUserOptions, 'label');

    const updatedResultOrderList = pricedOrderList.map(row => ({
      ...row,
      dateType,
      delivery_date: row.recv_delivery_date || row.delivery_date,
      // The isRowSelected flag is used to mark the row as being checked in the table
      isRowSelected: !!resultOrderSelections[row.id],
    }));

    const selectedResultOrderList = preSelectedCartList.reduce(
      (agg, order) => (resultOrderSelections[order.id] ? [...agg, order] : agg),
      []
    );

    const updatedCartList = formatRowsForCart(
      cartList.map(row => ({
        ...row,
        isRowSelected: !!cartSelections[row.id],
      })),
      { dateType }
    );

    const selectedCartOrderList = updatedCartList.reduce(
      (agg, order) => (cartSelections[order.id] ? [...agg, order] : agg),
      []
    );

    const isLoading = invoicing.isLoadingOrders || invoicing.isLoadingPricedOrders || isLockingPrices;

    const isUSAGrinder = isUSRegionGrinder(selectedGrinder);

    let hasError = false;
    if (selectedCart === CARTS.invoiceCart && !isUSAGrinder && !ignoreValidation) {
      hasError = !validateRows(selectedCartOrderList);
    }
    const pricingStrategy = getPricingStrategy(configs);
    let isManualPricing = false;
    if (endUserId) {
      isManualPricing = isManualPriceStrategyByConfigsAndEndUser(configs, endUsers, endUserId);
    }
    const defaultEndUserName = getDefaultValueFromConfigs(configs, 'end_user_default');
    const customSelectCart = isManualPricing ? Constants.FINANCE_CARTS.priceCart : selectedCart;
    const internalPoLabel = getInternalPoLabel(configs);
    const action_groups =
      this.state.invoiceType === 'final'
        ? ['invoice_final', 'internal_user']
        : this.state.invoiceType === 'prior'
        ? ['invoice_prior', 'internal_user']
        : ['invoice', 'internal_user'];

    const handleExcelExport = async () => {
      this.setState({
        isExportingExcel: true,
      });
      const { token } = this.props;
      const { dateStart, dateEnd, priceStatus } = this.state;
      const filters = {
        grinder: grinderUid,
        dateType,
        invoiceType,
        ...(priceStatus && { priceStatus }),
        ...(dateStart && { dateTypeStart: dateStart }),
        ...(dateEnd && { dateTypeEnd: dateEnd }),
        endUserId,
      };
      const excelData = await fetchOrderInvoicesToExport(token, filters);
      const { pricedOrderList: invoicingPricedOrderList } = invoicing;
      const excelUpdatedResultOrderList = excelData.map(order => {
        const match = invoicingPricedOrderList.find(pricedOrder => get(pricedOrder, 'purchase_order.id') === order.id);
        return {
          ...order,
          pricing_details: get(match, 'pricing_details'),
          dateType,
          delivery_date: order.recv_delivery_date || order.delivery_date,
        };
      });

      const propsObj = { ...this.props, internalPoLabel, dateType };
      const colsConfig =
        selectedTab === TABS.results
          ? configureColumns(propsObj, [
              'selector',
              'internal_po_number',
              'grinder_po_number',
              'grinder_name',
              'date_type',
              'po_weight',
              'shipped_weight',
              'remaining_weight',
              'received_weight',
              'status',
            ])
          : getCartConfig(
              propsObj,
              selectedCart,
              grinders.find(grinder => grinder.uid === grinderUid),
              invoiceType
            );
      const excelCols = colsConfig
        .filter(colConfig => colConfig.formattedValue != null)
        .reduce(
          (acc, curr) => [
            ...acc,
            {
              headerText: curr.name,
              getter: data => curr.formattedValue(data),
            },
          ],
          []
        );

      generateAndDownloadExcel(selectedTab, [
        {
          worksheetName: selectedTab,
          columnConfigs: excelCols,
          data: selectedTab === TABS.results ? excelUpdatedResultOrderList : updatedCartList,
        },
      ]);
      this.setState({
        isExportingExcel: false,
      });
    };

    const recipientsComponent = (
      <RecipientsCard
        disabled={false}
        grinderUid={selectedGrinder?.uid}
        notifying_action_groups={notifyingActionGroups}
        populateEmails={this.getSelectedEmailList}
        actionGroups={action_groups}
        defaultActionGroups={['invoice', 'internal_users']}
        extraEmails={[user.email]}
      />
    );
    return (
      <VStack gap="56px" align="stretch" marginBottom="95px">
        <FinanceNavMainTabs activeTabIndex={0} />
        <VStack align="stretch" paddingX="51px" gap="37px">
          <DashboardSectionTile title="Filter PO’s for Card">
            <InvoiceFilters
              {...{
                onSubmit: this.handleSearch,
                onCancel: this.handleCancelSearch,
                grinderOptions: sortedGrinderOptions,
                endUserOptions: sortedEndUserOptions,
                pricingStrategy,
                endUsers,
                defaultEndUserName,
              }}
            />
          </DashboardSectionTile>
          {/* <!-- LOADING --> */}
          {isLoading && (
            <Center>
              <Loading text="Loading orders..." />
            </Center>
          )}
          {hasSubmittedForm && !isLoading && (
            <Tabs.Root onValueChange={this.handleTabChange} defaultValue={selectedTab} value={selectedTab}>
              <Tabs.List width="max-content" borderBottomWidth="0px">
                <ResultTab key={TABS.results} value={TABS.results} title="Search Results" />
                <ResultTab key={TABS.cart} value={TABS.cart} title={startCase(selectedCart)} />
              </Tabs.List>
              <DashboardSectionTile borderTopLeftRadius="0px">
                {/* <!-- SEARCH RESULTS TAB --> */}
                <Tabs.Content value={TABS.results} padding={0}>
                  <InvoiceResults
                    {...{
                      dateType,
                      pagination: invoicing.orderPagination,
                      ordersList: updatedResultOrderList,
                      selectedRows: selectedResultOrderList,
                      onRowSelect: this.handleRowSelect,
                      onPageChange: this.handlePageChange,
                      packageWeights,
                      internalPoLabel,
                    }}
                  />
                </Tabs.Content>
                {/* <!-- CART TAB --> */}
                <Tabs.Content value={TABS.cart} padding={0}>
                  {hasError && <Alert status="error" title="Packer Paid Date is required to generate invoice." />}
                  {!isEmpty(priceLockingErrorList) && (
                    <Alert status="error">
                      <Box>
                        {priceLockingErrorList.map((msg, idx) => (
                          <p key={`lock-error-${idx}`}>{msg}</p>
                        ))}
                      </Box>
                    </Alert>
                  )}
                  <VStack gap="5px" align="stretch">
                    {mandatoryFieldsErrors.map((invoiceCartError, idx) => (
                      <Alert status="error" key={`err-${idx}`}>
                        {invoiceCartError}
                      </Alert>
                    ))}
                  </VStack>
                  <InvoiceCart
                    {...{
                      dateType,
                      invoiceType,
                      selectedCart: customSelectCart,
                      orderList: updatedCartList,
                      selectedRows: selectedCartOrderList,
                      grinder: selectedGrinder,
                      onRowSelect: this.handleRowSelect,
                      onReset: this.handleCartReset,
                      onPageChange: this.handlePageChange,
                      onGridRowUpdate: this.handleCartRowUpdate,
                      packageWeights,
                      internalPoLabel,
                    }}
                  />
                </Tabs.Content>

                {/* <!-- INVOICE TOTALS --> */}
                {hasSubmittedForm && !isLoading && (
                  <InvoiceTotals
                    buttonText={determineSubmitButtonText(selectedTab, selectedCart)}
                    onButtonClick={this.handleSelectionsSubmit}
                    selectedRows={selectedTab === TABS.results ? selectedResultOrderList : selectedCartOrderList}
                    label={[]}
                    disabled={
                      (selectedTab === TABS.results
                        ? selectedResultOrderList.length === 0
                        : selectedCartOrderList.length === 0) || hasError
                    }
                    packageWeights={packageWeights}
                    onClickExport={handleExcelExport}
                    isExportingExcel={isExportingExcel}
                  />
                )}
              </DashboardSectionTile>
            </Tabs.Root>
          )}
        </VStack>
        {/* <!-- INVOICE EMAIL MODAL --> */}
        {initialBody && initialSubject && (
          <InvoiceEmailModal
            {...{
              user,
              onSubmit: this.handleModalConfirm,
              onCancel: this.handleModalCancel,
              onBatchChange: this.handleBatchChange,
              onDownloadAllClick: this.handleDownloadAllAttachments,
              onAttachmentClick: this.handleDownloadAttachment,
              onGenerateSummary: this.handleGenerateInvoiceSummary,
              isVisible: emailModalIsVisible,
              orderList: selectedCartOrderList,
              pendingPDFDownloadList,
              isDownloadingAllAttachments,
              isGeneratingInvoiceSummary,
              isBatch,
              error: invoicing.error,
              isManualPricing,
              packageWeights,
              recipientsComponent,
              initialValues: {
                body: initialBody,
                subject: initialSubject,
              },
              isSendingInvoices: invoicing.isSendingInvoices,
            }}
          />
        )}
      </VStack>
    );
  }
}

export default FinancePage;
