import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { getCoords } from './helpers';
import DatePicker from '../core/DatePicker';
import Constants from '../../Constants';
import { get, set } from 'lodash';
import moment from 'moment';

const propTypes = {
  closeOnSelect: PropTypes.func,
  column: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  dateFormat: PropTypes.string,
  editorPortalTarget: PropTypes.node,
  disableGridMode: PropTypes.bool,
  inputProps: PropTypes.shape({
    borderColor: PropTypes.string,
  }),
  name: PropTypes.string,
  onRowChange: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  timeConstraints: PropTypes.shape({
    hours: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  timeFormat: PropTypes.string,
  value: PropTypes.string,
};

function DatePickerEditor(props) {
  const {
    closeOnSelect,
    dateFormat,
    editorPortalTarget,
    name,
    column,
    row,
    onRowChange,
    timeConstraints,
    timeFormat,
    inputProps,
    disableGridMode,
  } = props;
  const [value, setValue] = useState(get(row, column.key));

  const updateRow = useCallback(() => {
    const newRow = { ...row };
    set(newRow, column.key, value);
    onRowChange(newRow, true);
  }, [value]);

  useEffect(() => {
    return () => {
      updateRow();
    };
  }, []);

  const datePicker = (
    <DatePicker
      {...{
        closeOnSelect,
        dateFormat: dateFormat || Constants.DEFAULT_READ_DATE_FORMAT,
        inputProps: {
          ...inputProps,
          height: disableGridMode ? undefined : 26,
        },

        ...(Constants.VOYAGE_DATE_TYPES.find(d => d === column.key) ||
        Constants.INVOICE_GRID_DATE_TYPES.find(d => d === column.key)
          ? {}
          : {
              minDate: new Date(),
            }),
        name,
        onChange: current => {
          if (
            Constants.VOYAGE_DATE_TYPES.find(d => d === column.key) ||
            Constants.INVOICE_GRID_DATE_TYPES.find(d => d === column.key)
          ) {
            setValue(current);
            return current;
          }
          if (moment(current).isAfter(moment().subtract(1, 'day'))) {
            setValue(current);
            return current;
          }
        },
        timeConstraints,
        timeFormat,
        value,
        onBlur: () => setTimeout(updateRow, 1),
      }}
    />
  );

  if (disableGridMode) {
    return datePicker;
  }

  // the ref for the popup must be a callback ref
  const [editorRef, setEditorRef] = useState(null);

  let portalStyles = {
    top: 0,
    left: 0,
  };
  if (editorRef && editorRef.parentNode) {
    portalStyles = getCoords(editorRef.parentNode);
  }

  return (
    <>
      {/* Portal is rendered and positioned from the document.body node, 
      since absolute positioning
      is null inside of a grid-styled parent */}
      {editorRef &&
        createPortal(
          <div
            className="date-picker-portal"
            style={{
              zIndex: 9,
              paddingLeft: 13,
              width: editorRef.parentNode.clientWidth - 23,
              position: 'absolute',
              top: portalStyles.top + editorRef.parentNode.clientHeight / 2 - 13,
              left: portalStyles.left,
            }}
          >
            {datePicker}
          </div>,
          editorPortalTarget
        )}
      {/* Editor placeholder. Necessary for rendering portal */}
      <div ref={setEditorRef} tabIndex={-1} />
    </>
  );
}

DatePickerEditor.propTypes = propTypes;

export default DatePickerEditor;
