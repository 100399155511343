import React from 'react';
import { HStack, Tabs, Text, Flex } from '@chakra-ui/react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import { FaChevronRight } from 'react-icons/fa';

const tabsConfig = [
  { url: '/finance-invoicing', component: 'FinancePageComponent', title: 'Invoicing' },
  {
    url: '/finance-invoicing/drawdown-reports',
    component: 'DrawDownReportsComponent',
    title: 'Packer Bill Reports',
  },
];
export const FinanceNavMainTabs = ({ activeTabIndex, ...rest }) => {
  return (
    <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
      <Flex gap="20px" width="100%">
        <HStack>
          <Text as="p" fontWeight="700">
            Finance
          </Text>
          <FaChevronRight width="25px" height="25px" />
        </HStack>
        <Tabs.Root defaultValue={tabsConfig[0]?.title} index={activeTabIndex} {...rest}>
          <Tabs.List borderBottomWidth="0px">
            {tabsConfig.map(tab => (
              <Link to={tab.url} key={tab.title} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Tabs.Trigger
                  value={tab.title}
                  _focus={{ outline: 'none' }}
                  _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                  _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                  fontWeight="normal"
                  width="162px"
                  fontSize="14px"
                  padding="0px"
                  height="103px"
                  marginRight="10px"
                  justifyContent="center"
                >
                  {tab.title}
                </Tabs.Trigger>
              </Link>
            ))}
          </Tabs.List>
        </Tabs.Root>
      </Flex>
    </HorizontalNavigationBand>
  );
};

FinanceNavMainTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
};

export const ResultTab = ({ key, value, title, ...rest }) => {
  return (
    <Tabs.Trigger
      value={value}
      key={value}
      _focus={{ outline: 'none' }}
      borderTopRadius="6px"
      borderWidth="1px"
      borderColor="neutral.9"
      marginBottom="0px"
      _selected={{
        borderWidth: '0px',
        boxShadow: '-2px -1px 2px 0px var(--chakra-colors-gray-100)',
        fontWeight: 'bold',
      }}
      backgroundColor="card.default"
      marginRight="5px"
      justifyContent="center"
      {...rest}
    >
      {title}
    </Tabs.Trigger>
  );
};

ResultTab.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
