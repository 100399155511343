import { Box, Center, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { downloadFromS3Link } from '../../../../helpers/downloads';
import ResultsGrid from '../../../basic/ResultsGrid';
import { TextFormatter } from '../../../gridHelpers';
import { ReactComponent as DownloadIcon } from '../../../../assets/download-icon.svg';
import './DrawDownTable.scss';
import { isEmpty } from 'lodash';

class DrawDownTable extends Component {
  static propTypes = {
    ordersToDrawDown: PropTypes.arrayOf(PropTypes.object),
    selectedRows: PropTypes.arrayOf(PropTypes.object),
    onRowSelect: PropTypes.func.isRequired,
    tableInfo: PropTypes.string,
    displayTotalsText: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.state = {
      containerWidth: 1226,
      sortColumns: [],
      rows: props.ordersToDrawDown,
    };
  }

  componentDidMount() {
    this.setState({
      containerWidth: this.container.current.offsetWidth,
    });
  }

  columns = () => {
    return this.props.columnList.reduce((agg, columnConfig) => {
      // We assume that this is the SelectColumn config that is part
      // of the react-data-grid lib.  This is the checkbox element.
      if (columnConfig.key === 'select-row') {
        return [...agg, columnConfig];
      }

      // Otherwise, we configure the column as we normally would:
      const dataGridColumnConfig = {
        key: columnConfig.key,
        name: columnConfig.name,
        resizable: true,
        ...(this.props.columnList.length > 7 && {
          width: this.state.containerWidth / (this.props.columnList.length - 1) + 40,
        }),
        formatter: args => {
          const key = get(args, 'column.key');
          const value = get(args, `row.${key}`);
          switch (get(columnConfig, 'formatFunc', '')) {
            case 'formatDate':
              return this.formatDate(value);
            case 'download':
              return this.downloadFile(value);
            default:
              return TextFormatter(value);
          }
        },
        sortable: true,
      };

      return [...agg, dataGridColumnConfig];
    }, []);
  };

  formatDate = value => {
    if (!value) return <div>&nbsp;</div>;
    const formattedDate = moment(value).format('YYYY-MM-DD hh:mm A');
    return TextFormatter(formattedDate);
  };

  downloadFile = url => {
    return (
      <IconButton variant="link" marginY="5px" height="auto" onClick={() => downloadFromS3Link(url)}>
        <DownloadIcon />
      </IconButton>
    );
  };

  render() {
    const { ordersToDrawDown, onRowSelect, tableInfo, displayTotalsText, selectedRows } = this.props;
    const { rows, sortColumns } = this.state;
    return (
      <VStack align="stretch" gap="13px" ref={this.container}>
        {/* <!-- TEXT --> */}
        {tableInfo && <Text as="p">{tableInfo}</Text>}
        {displayTotalsText && (
          <HStack justifyContent="flex-end">
            <HStack>
              <Text as="p" fontWeight="semibold">
                Total selected POs:
              </Text>
              <Text as="p">{selectedRows.length}</Text>
            </HStack>
            <HStack>
              <Text as="p" fontWeight="semibold">
                Total POs:
              </Text>
              <Text as="p">{ordersToDrawDown.length}</Text>
            </HStack>
          </HStack>
        )}
        <Center>
          <Box width="100%" height="100%" className="drawdown-table">
            {/* <!-- GRID --> */}
            <ResultsGrid
              id="draw-down-table"
              className="draw-down-table__grid results-grid--minimal "
              {...{
                columns: this.columns(),
                rows,
                selectedRows,
                rowKeyGetter: row => `${row.internal_po_number}${row.grinder_po_number}`,
                onRowSelect,
              }}
              sortColumns={sortColumns}
              onSortColumnsChange={_sortColumns => {
                if (_sortColumns.length > 0) {
                  const sorted = [...ordersToDrawDown].sort((x, y) => {
                    const a = x[_sortColumns[0].columnKey];
                    const b = y[_sortColumns[0].columnKey];
                    if (a && b && typeof a === 'string' && typeof b === 'string') {
                      return _sortColumns[0].direction === 'ASC' ? a.localeCompare(b) : b.localeCompare(a);
                    }
                    if (isEmpty(a)) return _sortColumns[0].direction === 'ASC' ? -1 : 1;
                    if (isEmpty(b)) return _sortColumns[0].direction === 'ASC' ? 1 : -1;
                    return _sortColumns[0].direction === 'ASC' ? a - b : b - a;
                  });
                  this.setState({ rows: sorted });
                } else {
                  this.setState({ rows: ordersToDrawDown });
                }
                this.setState({ sortColumns: [..._sortColumns] });
              }}
            />
          </Box>
        </Center>
      </VStack>
    );
  }
}

export default DrawDownTable;
